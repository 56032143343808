import React, { useEffect, useRef, useState } from "react";
import ImageOptionsMenu from "../../../../components/SubHeader/components/ImageOptionsMenu";
import ConstructionService from "../../../../services/ConstructionService";
import { useDashboard } from "../../../../contexts/dashboard";
import { api } from "../../../../services/api";
import * as Styles from "./SubHeader.styles";
import Snackbar from "@mui/material/Snackbar";

interface SubHeaderPccProps {
  showFinishedItems: boolean;
  onChangeShowFinishedItems: (value: boolean) => void;
}

const SubHeaderPcc: React.FC<SubHeaderPccProps> = ({
  showFinishedItems,
  onChangeShowFinishedItems,
}) => {
  const { constructionId, constructions } = useDashboard();
  const constructionService = new ConstructionService();

  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [constructionImage, setConstructionImage] = useState<string>("");
  const [selectedConstructionName, setSelectedConstructionName] = useState("");
  const [imageOptionsOpen, setImageOptionsOpen] = useState<boolean>(false);
  const imageOptionsRef = useRef<HTMLDivElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const toggleImageOptions = () => setImageOptionsOpen(!imageOptionsOpen);

  const getConstructionInitialLetters = (construction: string): string => {
    const array = construction.split(" ");
    let firstLetter = array[0]?.charAt(0);
    let lastLetter = array.length > 1 ? array[array.length - 1]?.charAt(0) : "";

    return `${firstLetter}${lastLetter}`;
  };

  const getConstructionImage = async (constructionId: number) => {
    try {
      const result = await api.get(
        `/api/constructions/image/${constructionId}`
      );
      setConstructionImage(result.data);
    } catch (error) {
      console.error(error);
      setConstructionImage("");
    }
  };

  const handleDeleteImage = async () => {
    try {
      const deletionSuccessful =
        await constructionService.deleteConstructionImage(constructionId);
      if (deletionSuccessful) {
        await getConstructionImage(constructionId);
        setOpenSnackbar(true);
        setSnackbarMessage("Imagem excluída com sucesso");
      } else {
        setOpenSnackbar(true);
        setSnackbarMessage("Erro ao excluir a imagem");
      }
    } catch (error) {
      setOpenSnackbar(true);
      setSnackbarMessage("Erro ao excluir a imagem");
    } finally {
      setImageOptionsOpen(false);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      imageOptionsRef.current &&
      !imageOptionsRef.current.contains(event.target as Node)
    )
      setImageOptionsOpen(false);
  };

  const handleFileSelect = (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    constructionService
      .updateImage(constructionId?.toString(), formData)
      .then((response) => {
        if (!response) return;
        setImageOptionsOpen(false);
        setConstructionImage(response);
        setSelectedFile(null);
        setOpenSnackbar(true);
        setSnackbarMessage("Imagem atualizada com sucesso!");
      })
      .catch((error) => {
        setOpenSnackbar(true);
        setSnackbarMessage("Erro ao atualizar a imagem");
      });
  };

  useEffect(() => {
    getConstructionImage(constructionId);
  }, [constructionId]);

  useEffect(() => {
    if (constructionId) {
      const constructionName = constructions.find(
        (construction) => construction.id === constructionId
      );
      setSelectedConstructionName(constructionName?.name || "");
    }
  }, [constructionId, constructions]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <>
      <Styles.HeaderContainer>
        <Styles.AboutContainer>
          {constructionImage ? (
            <Styles.ConstructionImage
              onClick={toggleImageOptions}
              src={constructionImage}
            />
          ) : (
            <Styles.ConstructionImagePlaceholder onClick={toggleImageOptions}>
              {getConstructionInitialLetters(selectedConstructionName)}
            </Styles.ConstructionImagePlaceholder>
          )}
          <Styles.TitleContainer>
            <Styles.PageTitle>Compras e Contratações</Styles.PageTitle>
            <Styles.ConstructionNameTitle>
              {selectedConstructionName}
            </Styles.ConstructionNameTitle>
          </Styles.TitleContainer>

          {imageOptionsOpen && (
            <div ref={imageOptionsRef}>
              <ImageOptionsMenu
                top="105px"
                left="60px"
                onDeleteService={handleDeleteImage}
                onFileSelect={handleFileSelect}
              />
            </div>
          )}
        </Styles.AboutContainer>
        <Styles.TimeContainer>
          <Styles.GroupByTimeContainer>
            <Styles.GroupByTimeText>
              Exibir pedidos finalizados
            </Styles.GroupByTimeText>
            <Styles.SwitchTime
              checked={showFinishedItems}
              onChange={(event, checked) => onChangeShowFinishedItems(checked)}
            />
          </Styles.GroupByTimeContainer>
        </Styles.TimeContainer>
      </Styles.HeaderContainer>
      <Snackbar
        message={snackbarMessage}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => {
          setOpenSnackbar(false);
        }}
      />
    </>
  );
};

export default SubHeaderPcc;
