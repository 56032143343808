import LoadingButton from "@mui/lab/LoadingButton";
import { LinearProgress } from "@mui/material";
import { jsPDF } from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import styled from "styled-components";
import { Colors } from "../../../theme/v2/variants";

import {
  baseData,
  FINANCIAL_PROGRESS,
  mixedChartData,
  PHYSICAL_PROGRESS,
} from "../pages/ServiceReportPage";

import curvedEvolutionIcon from "../../../assets/images/curve-evolution-section.png";
import imageIcon from "../../../assets/images/image-outline-yellow.png";
import indiceIcon from "../../../assets/images/indice-icon.png";

import { ReactComponent as ShareOutlined } from "../../../assets/share-outline.svg";
import { useAuth } from "../../../contexts/auth";
import { useDashboard } from "../../../contexts/dashboard";
import { api } from "../../../services/api";
import { VerticalServiceReport } from "../../../services/interfaces/VerticalServiceReport";

import GbLogo from "../../../assets/logo-gb.png";

import dayjs from "dayjs";
import KeyboardArrowRightIcon from "../../../assets/keyboard-arrow-right.png";
import LabeledItemCard from "../../../components/Cards/LabeledItemCard/LabeledItemCard";
import ConstructionService from "../../../services/ConstructionService";
import OptionsToExportPopUp from "./OptionsToExportPopUp";

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);
interface ExportOptions {
  includeProgress: boolean;
  includeGraph: boolean;
  includeImages: boolean;
  includeRescheduling: boolean;
}

const pdfA4Metrics = {
  pagePadding: 15,
  maxWidth: 605,
};

type ServiceReportPdfProps = {
  chartData: typeof baseData;
  reschedulingChartData: typeof mixedChartData;
  reschedulingChartOptions: any;
  reportData: VerticalServiceReport | undefined;
  filterItems: { name: string; checked: boolean }[];
  reportDates?: string;
  sortedImages: VerticalServiceReport["images"];
};

const ServiceReportExportToPdf = ({
  chartData,
  reschedulingChartData,
  reschedulingChartOptions,
  filterItems,
  reportData,
  reportDates,
  sortedImages,
}: ServiceReportPdfProps) => {
  const Construction = new ConstructionService();
  const [generating, setGenerating] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const pdfRefPage1 = useRef(null);
  const pdfRefPage2 = useRef(null);
  const pdfRefPage3 = useRef(null);
  const pdfRefPage4 = useRef(null);
  const { user } = useAuth();
  const [constructionImage, setConstructionImage] = useState("");
  const { constructionId } = useDashboard();
  const [constructionName, setConstructionName] = useState("");

  const [options, setOptions] = useState({
    includeProgress: true,
    includeGraph: true,
    includeImages: true,
    includeRescheduling: true,
  });

  const parsedChartData = {
    ...chartData,
    datasets: [
      ...chartData.datasets.map((dataset) => ({
        ...dataset,
        pointRadius: 3,
        borderWidth: 1,
      })),
    ],
  };

  const parsedReschedulingChartData = {
    ...reschedulingChartData,
    datasets: [
      ...reschedulingChartData.datasets.map((dataset) => ({
        ...dataset,
        pointRadius: 3,
        borderWidth: 1,
      })),
    ],
  };

  const isPhysicalProgressAndFinancialProgressNotChecked =
    !filterItems.find((item) => item.name === PHYSICAL_PROGRESS)?.checked &&
    !filterItems.find((item) => item.name === FINANCIAL_PROGRESS)?.checked;

  const findConstructionImage = async (constructionId: number) => {
    const response = await Construction.getConstructionImage(constructionId);
    setConstructionImage(response);
  };

  useEffect(() => {
    if (user?.id) {
      findConstructionImage(constructionId);

      const fetchConstructionName = async () => {
        try {
          const response = await api.get(
            `api/constructions/info/${constructionId}`
          );
          setConstructionName(response.data.name);
        } catch (error) {
          console.error(error);
        }
      };
      fetchConstructionName();
    }
  }, [constructionId, findConstructionImage, user?.id]);

  const shareOnClick = (options: ExportOptions) => {
    setGenerating(true);

    const inputPage1 = pdfRefPage1.current as HTMLElement | null;
    const inputPage2 = pdfRefPage2.current as HTMLElement | null;
    const inputPage3 = pdfRefPage3.current as HTMLElement | null;
    const inputPage4 = pdfRefPage4.current as HTMLElement | null;

    if (!inputPage1 && !inputPage2 && !inputPage3 && !inputPage4) {
      setGenerating(false);
      return;
    }

    const reportFileName = `relatorio-de-servicos_${reportData?.construction.name}_${reportDates}.pdf`;

    const doc = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: "a4",
      putOnlyUsedFonts: true,
    });

    const addImagesRecursively = (
      doc: any,
      imageContent: any,
      remainingPageHeight: number,
      i = 0
    ) => {
      if (
        i === imageContent.children.length ||
        options.includeImages === false
      ) {
        setGenerating(false);
        doc.save(reportFileName);
      } else if (imageContent.children[i].clientHeight <= remainingPageHeight) {
        remainingPageHeight =
          remainingPageHeight - imageContent.children[i].clientHeight;
        doc.html(imageContent.children[i] as any, {
          y:
            doc.internal.pageSize.height * (doc.internal.pages.length - 2) + 15,
          callback: function (updatedDoc: any) {
            addImagesRecursively(
              updatedDoc,
              imageContent,
              remainingPageHeight,
              i + 1
            );
          },
        });
      } else {
        if (i > 0) doc.addPage("a4", "landscape");
        remainingPageHeight =
          doc.internal.pageSize.height - imageContent.children[i].clientHeight;

        doc.html(imageContent.children[i] as any, {
          y:
            doc.internal.pageSize.height * (doc.internal.pages.length - 2) + 15,
          callback: function (updatedDoc: any) {
            addImagesRecursively(
              updatedDoc,
              imageContent,
              remainingPageHeight,
              i + 1
            );
          },
        });
      }
    };

    if (options.includeProgress && inputPage1) {
      doc.html(inputPage1, {
        margin: [15, 0, 15, 0],
        autoPaging: "text",
        callback: (doc: any) => {
          if (
            options.includeGraph &&
            !isPhysicalProgressAndFinancialProgressNotChecked &&
            inputPage2
          ) {
            doc.addPage("a4", "landscape");
          }

          if (options.includeGraph && inputPage2) {
            doc.html(inputPage2, {
              autoPaging: "text",
              y:
                doc.internal.pageSize.height * (doc.internal.pages.length - 2) +
                15,
              callback: (doc: any) => {
                // Verifica se incluirá a página 4 (igual a página 2)
                if (options.includeRescheduling && inputPage4) {
                  doc.addPage("a4", "landscape");
                  doc.html(inputPage4, {
                    autoPaging: "text",
                    y:
                      doc.internal.pageSize.height *
                        (doc.internal.pages.length - 2) +
                      15,
                    callback: (doc: any) => {
                      // Após adicionar a página 4, continue com a página 3, se necessário
                      if (options.includeImages && inputPage3) {
                        doc.addPage("a4", "landscape");
                        addImagesRecursively(
                          doc,
                          inputPage3,
                          doc.internal.pageSize.height
                        );
                      } else {
                        setGenerating(false);
                        doc.save(reportFileName);
                      }
                    },
                  });
                } else if (options.includeImages && inputPage3) {
                  doc.addPage("a4", "landscape");
                  addImagesRecursively(
                    doc,
                    inputPage3,
                    doc.internal.pageSize.height
                  );
                } else {
                  setGenerating(false);
                  doc.save(reportFileName);
                }
              },
            });
          } else if (options.includeRescheduling && inputPage4) {
            doc.addPage("a4", "landscape");
            doc.html(inputPage4, {
              autoPaging: "text",
              y:
                doc.internal.pageSize.height * (doc.internal.pages.length - 2) +
                15,
              callback: (doc: any) => {
                // Após adicionar a página 4, continue com a página 3, se necessário
                if (options.includeImages && inputPage3) {
                  doc.addPage("a4", "landscape");
                  addImagesRecursively(
                    doc,
                    inputPage3,
                    doc.internal.pageSize.height
                  );
                } else {
                  setGenerating(false);
                  doc.save(reportFileName);
                }
              },
            });
          } else if (options.includeImages && inputPage3) {
            doc.addPage("a4", "landscape");
            addImagesRecursively(doc, inputPage3, doc.internal.pageSize.height);
          } else {
            setGenerating(false);
            doc.save(reportFileName);
          }
        },
      });
    } else if (options.includeGraph && inputPage2) {
      doc.html(inputPage2, {
        margin: [15, 0, 15, 0],
        autoPaging: "text",
        y: doc.internal.pageSize.height * (doc.internal.pages.length - 2) + 15,
        callback: (doc: any) => {
          if (options.includeRescheduling && inputPage4) {
            doc.html(inputPage4, {
              autoPaging: "text",
              y:
                doc.internal.pageSize.height * (doc.internal.pages.length - 2) +
                15,
              callback: (doc: any) => {
                if (options.includeImages && inputPage3) {
                  doc.addPage("a4", "landscape");
                  addImagesRecursively(
                    doc,
                    inputPage3,
                    doc.internal.pageSize.height
                  );
                } else {
                  setGenerating(false);
                  doc.save(reportFileName);
                }
              },
            });
          } else if (options.includeImages && inputPage3) {
            doc.addPage("a4", "landscape");
            addImagesRecursively(doc, inputPage3, doc.internal.pageSize.height);
          } else {
            setGenerating(false);
            doc.save(reportFileName);
          }
        },
      });
    } else if (options.includeImages && inputPage3) {
      doc.html(inputPage3, {
        margin: [15, 0, 15, 0],
        autoPaging: "text",
        callback: function () {
          setGenerating(false);
          doc.save(reportFileName);
        },
      });
    } else if (options.includeRescheduling && inputPage4) {
      doc.html(inputPage4, {
        autoPaging: "text",
        y: doc.internal.pageSize.height * (doc.internal.pages.length - 2) + 15,
        callback: function () {
          // Após adicionar a página 4 isolada, continue com a página 3, se necessário
          if (options.includeImages && inputPage3) {
            doc.addPage("a4", "landscape");
            addImagesRecursively(doc, inputPage3, doc.internal.pageSize.height);
          } else {
            setGenerating(false);
            doc.save(reportFileName);
          }
        },
      });
    }
  };

  let addGap = false;
  let gapAdded = false;
  var previousHeight = 108;
  var actualHeight = 0;
  var remainingHeight = 416;
  let serviceTypeHeight = 35;
  let serviceGroupHeight = 36;
  let pageHeight = 416;

  const resetValues = () => {
    addGap = false;
    gapAdded = false;
    previousHeight = 0;
    actualHeight = 0;
    remainingHeight = 416;
    return null;
  };

  const renderLinearProgress = (
    groupName: string,
    progressAsPercent: number
  ) => (
    <GridContainerAlignStart>
      <TitleContainer>
        <GroupName>{groupName.replace("/", " / ")}</GroupName>
        <GroupPercentage>
          {progressAsPercent
            ? Number.parseFloat(progressAsPercent.toFixed(2))
            : 0}
          %
        </GroupPercentage>
      </TitleContainer>
      <LinearProgress
        sx={{
          backgroundColor: "rgba(217, 217, 217, 0.5)",
          "& .MuiLinearProgress-bar": {
            backgroundColor: Colors.sweetPink,
          },
          height: "4px",
          borderRadius: "9px",
          width: `${pdfA4Metrics.maxWidth}px`,
        }}
        variant="determinate"
        value={
          progressAsPercent === null
            ? 0
            : progressAsPercent > 100
            ? 100
            : Number.parseFloat(progressAsPercent.toFixed(2))
        }
      />
    </GridContainerAlignStart>
  );

  const renderServiceTypesLinearProgress = (
    typeName: string,
    progressAsPercent: number,
    isLastItem: boolean
  ) => {
    return (
      <GridContainerAlignStart
        style={{
          paddingBottom:
            addGap && !gapAdded && !isLastItem ? `${remainingHeight}px` : "0",
        }}
      >
        <ServiceTypesTitleContainer>
          <TitleWithButtonContainer>
            <PhysicalChildArrowIcon
              crossOrigin="anonymous"
              src={KeyboardArrowRightIcon}
            />
            <ServiceTypeTitle>{typeName}</ServiceTypeTitle>
          </TitleWithButtonContainer>
          <GroupPercentage>
            {progressAsPercent
              ? Number.parseFloat(progressAsPercent.toFixed(2))
              : 0}
            %
          </GroupPercentage>
        </ServiceTypesTitleContainer>
        <LinearProgress
          sx={{
            backgroundColor: "rgba(217, 217, 217, 0.5)",
            "& .MuiLinearProgress-bar": {
              backgroundColor: Colors.dullLavender,
            },
            height: "3px",
            borderRadius: "9px",
            width: `${pdfA4Metrics.maxWidth - 24 * 2}px`,
          }}
          variant="determinate"
          value={
            progressAsPercent === null
              ? 0
              : progressAsPercent > 100
              ? 100
              : Number.parseFloat(progressAsPercent.toFixed(2))
          }
        />
      </GridContainerAlignStart>
    );
  };

  let globalImageIndex = 0;
  let gapCount = 0;

  const PdfHeader: React.FC = () => (
    <FlexContainer>
      <PageHeaderInfo>
        {constructionImage ? (
          <LogoClient crossOrigin="anonymous" src={constructionImage} />
        ) : (
          <LogoGB crossOrigin="anonymous" src={GbLogo} />
        )}
        <FlexContainerColumn>
          <PDFTitle>Relatório de Serviços</PDFTitle>
          <ConstructionTitle>{constructionName}</ConstructionTitle>
          <ResponsibleName>
            Responsável: {reportData?.responsibleNames}
          </ResponsibleName>
          <ResponsibleName>Período: {reportDates}</ResponsibleName>
        </FlexContainerColumn>
        <LogoGB crossOrigin="anonymous" src={GbLogo} />
      </PageHeaderInfo>
    </FlexContainer>
  );

  const pdfModel = (
    <Container>
      <Page ref={pdfRefPage1}>
        <PdfHeader />
        <div>
          <FlexContainerAlignCenter>
            <SectionIcon crossOrigin="anonymous" src={indiceIcon} />
            <SectionTitle>Avanços Físicos</SectionTitle>
          </FlexContainerAlignCenter>
          <div>
            {reportData?.physicalProgress.map((indice, index) => {
              if (previousHeight + serviceGroupHeight > pageHeight) {
                addGap = true;
              } else {
                actualHeight = previousHeight + serviceGroupHeight;
                remainingHeight = pageHeight - actualHeight;
                previousHeight = actualHeight;
              }

              return (
                <PhysicalContainer key={indice.groupName}>
                  {addGap && !gapAdded && remainingHeight > 0 && (
                    <>
                      <ProgressPageGap height={remainingHeight} />
                      {resetValues()}
                    </>
                  )}
                  {indice.progressAsPercent !== 0 && (
                    <>
                      {renderLinearProgress(
                        indice.groupName,
                        indice.progressAsPercent
                      )}
                      {indice.groupName !== "Total" && (
                        <ServiceTypesContainer>
                          {indice.serviceTypes?.map(
                            (serviceType, serviceIndex) => {
                              const isLastItem =
                                serviceIndex ===
                                indice.serviceTypes!.length - 1;
                              if (
                                previousHeight + serviceTypeHeight >
                                pageHeight
                              ) {
                                addGap = true;
                              } else {
                                actualHeight =
                                  previousHeight + serviceTypeHeight;
                                remainingHeight = pageHeight - actualHeight;
                                previousHeight = actualHeight;
                              }

                              if (addGap && !gapAdded) {
                                return (
                                  <>
                                    <ProgressPageGap height={remainingHeight} />
                                    {resetValues()}
                                  </>
                                );
                              }

                              return renderServiceTypesLinearProgress(
                                serviceType.typeName,
                                serviceType.physicalProgressPercent,
                                isLastItem
                              );
                            }
                          )}
                        </ServiceTypesContainer>
                      )}
                    </>
                  )}
                </PhysicalContainer>
              );
            })}
          </div>
        </div>
      </Page>
      <Page ref={pdfRefPage2}>
        {!options.includeProgress && <PdfHeader />}
        <div>
          <FlexContainerAlignCenter>
            <SectionIcon crossOrigin="anonymous" src={curvedEvolutionIcon} />
            <SectionTitle>Evolução dos serviços</SectionTitle>
          </FlexContainerAlignCenter>
          <GraphContainer>
            <LineChart>
              <Line
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      ticks: {
                        font: {
                          size: 12,
                        },
                      },
                    },
                    y: {
                      ticks: {
                        font: {
                          size: 12,
                        },
                      },
                    },
                  },
                  plugins: {
                    title: { display: false },
                    legend: { display: false },
                  },
                }}
                plugins={[
                  {
                    id: "whiteBackground",
                    beforeDraw: (chartInstance: any) => {
                      let ctx = chartInstance.ctx;
                      ctx.fillStyle = "white";
                      ctx.fillRect(
                        0,
                        0,
                        chartInstance.width,
                        chartInstance.height
                      );
                    },
                  },
                ]}
                data={parsedChartData}
              />
            </LineChart>
            <FlexContainerJustifyCenter>
              <GraphLabelContainer>
                <GraphLabelIconPrimary />
                <GraphLabelText>{"Físico Real Acumulado"}</GraphLabelText>
              </GraphLabelContainer>
              <GraphLabelContainer>
                <GraphLabelIconSecondary />
                <GraphLabelText>{"Físico Planejado Acumulado"}</GraphLabelText>
              </GraphLabelContainer>
            </FlexContainerJustifyCenter>
          </GraphContainer>
        </div>
      </Page>
      <Page ref={pdfRefPage4}>
        {!options.includeProgress &&
          !options.includeGraph &&
          !options.includeImages && <PdfHeader />}
        <div>
          <FlexContainerAlignCenter>
            <SectionIcon crossOrigin="anonymous" src={curvedEvolutionIcon} />
            <SectionTitle>Causas de reprogramação</SectionTitle>
          </FlexContainerAlignCenter>
          <GraphContainer>
            <LineChart>
              <Chart
                type="line"
                options={reschedulingChartOptions}
                plugins={[
                  {
                    id: "whiteBackground",
                    beforeDraw: (chartInstance: any) => {
                      let ctx = chartInstance.ctx;
                      ctx.fillStyle = "white";
                      ctx.fillRect(
                        0,
                        0,
                        chartInstance.width,
                        chartInstance.height
                      );
                    },
                  },
                ]}
                data={parsedReschedulingChartData}
              />
            </LineChart>
            <FlexContainerJustifyCenter>
              <GraphLabelContainer>
                <GraphLabelIconPrimary />
                <GraphLabelText>{"Quantidade de ocorrências"}</GraphLabelText>
              </GraphLabelContainer>
              <GraphLabelContainer>
                <GraphLabelIconSecondary />
                <GraphLabelText>{"% acumulada"}</GraphLabelText>
              </GraphLabelContainer>
            </FlexContainerJustifyCenter>
          </GraphContainer>
        </div>
      </Page>
      {reportData?.images && reportData.images.length > 0 && (
        <Page ref={pdfRefPage3}>
          {!options.includeProgress && !options.includeGraph && <PdfHeader />}
          <div>
            <FlexContainerAlignCenterWithPaddingLeft>
              <SectionIcon crossOrigin="anonymous" src={imageIcon} />
              <SectionTitle>Relatório Fotográfico</SectionTitle>
            </FlexContainerAlignCenterWithPaddingLeft>
            <FlexContainerWithGap>
              {sortedImages.reduce<React.ReactNode[]>((acc, serviceType) => {
                const renderedImages = serviceType.imageUrl.map(
                  (image, index) => {
                    const shouldAddGap = (globalImageIndex + 1) % 6 === 0;
                    globalImageIndex++;

                    const hasHeader =
                      !options.includeProgress &&
                      !options.includeGraph &&
                      options.includeImages;

                    const currentHasHeader = gapCount === 0 ? hasHeader : false;
                    return (
                      <React.Fragment key={serviceType.imageId[index]}>
                        <ImageListItem hasHeader={currentHasHeader}>
                          <ImageListContainer>
                            <ServiceImage
                              hasHeader={currentHasHeader}
                              crossOrigin="anonymous"
                              src={image}
                              alt="Imagem do serviço"
                            />
                          </ImageListContainer>
                          <ImageListDescription>
                            <ImageListDescriptionFirstLine>
                              <LimitedWidthLabeledItemCard
                                title="Serviço"
                                text={serviceType.name}
                                fontWeight={700}
                                fontSize="8px"
                                titleFontSize="7px"
                                textWrap
                              />
                              <LabeledItemCard
                                title="Data"
                                text={dayjs(
                                  serviceType.createdAt[index]
                                ).format("DD/MM/YYYY")}
                                fontWeight={600}
                                fontSize="8px"
                                titleFontSize="7px"
                              />
                            </ImageListDescriptionFirstLine>
                          </ImageListDescription>
                        </ImageListItem>

                        {shouldAddGap &&
                          (() => {
                            gapCount++;
                            return (
                              <PicturesPageGap hasHeader={currentHasHeader} />
                            );
                          })()}
                      </React.Fragment>
                    );
                  }
                );

                return [...acc, ...renderedImages];
              }, [])}
            </FlexContainerWithGap>
          </div>
        </Page>
      )}
    </Container>
  );

  const [hover, setHover] = useState(false);

  const buttonStyle: React.CSSProperties = {
    color: Colors.primaryColor,
    borderRadius: "70px",
    border: "1px solid " + Colors.neutralGrayLight,
    fontWeight: 600,
    fontSize: "12px",
    maxHeight: "35px",
    backgroundColor: hover ? "#e6e4e8" : "#00000000",
    textAlign: "center",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: 0.1,
    textTransform: "none",
    fontFamily: "Noto Sans",
    height: "40px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "14px",
    paddingRight: "20px",
    whiteSpace: "nowrap",
  };

  return (
    <>
      {pdfModel}
      <LoadingButton
        variant="outlined"
        loadingPosition={"start"}
        loading={generating}
        disabled={generating}
        style={buttonStyle}
        onClick={() => setModalOpen(true)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        startIcon={<ShareOutlined />}
      >
        Exportar PDF
      </LoadingButton>

      <OptionsToExportPopUp
        isOpen={modalOpen}
        cancelOnClick={() => setModalOpen(false)}
        onExport={(options) => {
          setOptions(options);
          shareOnClick(options);
        }}
      />
    </>
  );
};

const LimitedWidthLabeledItemCard = styled(LabeledItemCard)`
  max-width: 100px;
`;

const ImageListItem = styled.div<{ hasHeader: boolean }>`
  display: grid;
  width: ${pdfA4Metrics.maxWidth / 3.19}px;
  height: ${(props) => (props.hasHeader ? "130px" : "auto")}
  grid-template-rows: min-content min-content;
  background-color: ${Colors.neutralColorWhite};
  gap: 8px;
  padding: 8px;
  border-radius: 16px;
  border: 1px solid rgba(178, 178, 178, 0.25);
  -webkit-box-shadow: 0px 30px 30px -30px rgba(59, 66, 90, 0.5);
  -moz-box-shadow: 0px 30px 30px -30px rgba(59, 66, 90, 0.5);
  box-shadow: 0px 30px 30px -30px rgba(59, 66, 90, 0.5);
`;

const ImageListContainer = styled.div`
  display: grid;
  align-content: center;
  justify-content: center;
`;

const ServiceImage = styled.img<{ hasHeader: boolean }>`
  width: ${(pdfA4Metrics.maxWidth - 15 * 2 - 16 * 2) / 3.19}px;
  height: ${(props) => (props.hasHeader ? "90px" : "112px")};
  object-fit: cover;
`;

const ImageListDescription = styled.div`
  display: grid;
  color: ${Colors.textColorBlack};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  gap: 6px;
`;

const ImageListDescriptionFirstLine = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: space-between;
  gap: 8px;
  border-bottom: 1px solid rgba(178, 178, 178, 0.25);
`;

const LineChart = styled.div`
  height: 250px;
  width: ${pdfA4Metrics.maxWidth / 1.2}px;
`;

const PhysicalContainer = styled.div`
  max-width: ${pdfA4Metrics.maxWidth}px;
  height: auto;
`;

const ServiceTypesContainer = styled.div`
  display: grid;
  background-color: ${Colors.alabster};
  padding-top: 4px;
  padding-bottom: 8px;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 8px;
  border: 1px solid ${Colors.concrete};
  width: ${pdfA4Metrics.maxWidth}px;
`;

const TitleWithButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 19px;
`;

const ServiceTypeTitle = styled.div`
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${Colors.neutralGrayDark};
`;

const GridContainerAlignStart = styled.div`
  display: grid;
  grid-template-rows: 1fr max-content;
  align-content: start;
  margin-bottom: 4px;
`;

const TitleContainer = styled.div`
  display: flex;
  width: ${pdfA4Metrics.maxWidth}px;
  justify-content: space-between;
  align-items: center;
`;

const ServiceTypesTitleContainer = styled.div`
  display: flex;
  width: ${pdfA4Metrics.maxWidth - 24 * 2}px;
  justify-content: space-between;
  align-items: center;
`;

const LogoClient = styled.img`
  height: auto;
  width: 80px;
`;

const LogoGB = styled.img`
  height: auto;
  width: 80px;
`;

const PDFTitle = styled.div`
  font-size: 10px;
  text-align: center;
  line-height: normal;
`;

const ConstructionTitle = styled.div`
  font-size: 10px;
  font-weight: bold;
  text-align: center;
`;

const Container = styled.div`
  position: absolute;
  left: -9999px;
  top: -9999px;
  //position: relative;
  //left: -599px;
  //top: 0;
`;

const Page = styled.div`
  padding: 0 ${pdfA4Metrics.pagePadding}px;
`;

const PageHeaderInfo = styled.div`
  width: ${pdfA4Metrics.maxWidth}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ResponsibleName = styled.span`
  font-size: 8px;
  color: ${Colors.textColorGray};
`;

const FlexContainer = styled.div`
  display: flex;
`;

const FlexContainerAlignCenter = styled(FlexContainer)`
  align-items: center;
`;

const FlexContainerAlignCenterWithPaddingLeft = styled(FlexContainer)`
  align-items: center;
  padding-left: 10px;
`;

const FlexContainerWithGap = styled(FlexContainer)`
  max-width: ${pdfA4Metrics.maxWidth}px;
  gap: 10px;
  padding-left: 10px;
  flex-wrap: wrap;
`;

const FlexContainerJustifyCenter = styled(FlexContainer)`
  justify-content: center;
`;

const FlexContainerColumn = styled(FlexContainer)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SectionIcon = styled.img`
  width: 8px;
  height: 10px;
  margin-right: 2px;
  padding-top: 2px;
`;

const PhysicalChildArrowIcon = styled.img`
  width: 5px;
  height: 7px;
`;

const SectionTitle = styled.h1`
  font-size: 10px;
`;

const GroupName = styled.p`
  font-weight: bold;
  font-size: 8px;
  padding-right: 5px;
`;

const GroupPercentage = styled.p`
  font-size: 8px;
`;

const GraphContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;
  width: ${pdfA4Metrics.maxWidth}px;
`;

const GraphLabelContainer = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
`;

const GraphLabelText = styled.p`
  word-wrap: break-word;
  margin-left: 4px;
  font-size: 8px;
`;

const GraphLabelIcon = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-right: 4px;
  padding-top: 3px;
`;

const GraphLabelIconPrimary = styled(GraphLabelIcon)`
  background-color: ${Colors.dullLavender};
`;

const GraphLabelIconSecondary = styled(GraphLabelIcon)`
  background-color: ${Colors.sweetPink};
`;

const PicturesPageGap = styled.div<{ hasHeader: boolean }>`
  width: ${pdfA4Metrics.maxWidth}px;
  height: ${(props) => (props.hasHeader ? "15px" : "84.6px")};
`;

const ProgressPageGap = styled.div<{ height: number }>`
  width: ${pdfA4Metrics.maxWidth}px;
  height: ${({ height }) => (height ? `${height}px` : "0px")};
`;

export { ServiceReportExportToPdf };
