import React, { useLayoutEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5localesPt_BR from "@amcharts/amcharts5/locales/pt_BR";

interface APIData {
  id: number;
  name: string;
  color: string;
  services: {
    id: number;
    name: string;
    position: number;
    verticalServiceGroupId: number;
    isAddByUser: boolean;
    gridItems: {
      isEmpty: boolean;
      rowTemplate: string;
      subItems: {
        date: string;
        width: number;
        startSpace: number;
        endSpace: number;
        weekdaysOnly: boolean;
        totalDays: number;
        status: {
          name: string;
          value: number;
        };
        conclusionDate: string | null;
        id: number;
        description: string;
        quantity: number;
        unit: string;
        materialCost: number;
        workforceCost: number;
        location: string;
        responsibleUserId: {
          id: string;
          name: string;
          login: string;
          avatar: string;
          role: number;
          status: boolean;
          responsibleClientId: string;
          createdAt: string;
          updatedAt: string;
        };
        beginDate: string;
        endDate: string;
        verticalServiceTypeId: number;
        verticalServiceGroupId: number;
        registerUserId: number;
        constructionId: number;
        createdAt: string;
        updatedAt: string;
        lastUpdatedByUserId: string;
        duration: number | null;
        uniqueApplicationId: string;
      }[];
      firstDate: string;
    }[];
    gridTemplate: string;
    firstDate: string;
  }[];
}

const GanttChart: React.FC<{ data: APIData[]; visualizationMode: string }> = ({
  data,
  visualizationMode,
}) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [chartHeight, setChartHeight] = useState(500);

  useLayoutEffect(() => {
    if (!chartRef.current || !data || data.length === 0) return;

    const root = am5.Root.new(chartRef.current);

    root.locale = am5localesPt_BR;
    root.setThemes([am5.Theme.new(root)]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "none",
        wheelY: "none",
        layout: root.verticalLayout,
      })
    );

    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.1,
        groupData: true,
        baseInterval: { timeUnit: "day", count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
        dateFormats: {
          day: "dd/MM/yyyy",
          week: "dd/MM/yyyy",
          month: "MMM yyyy",
          year: "yyyy",
        },
        periodChangeDateFormats: {
          day: "dd/MM/yyyy",
          week: "dd/MM/yyyy",
          month: "MMM yyyy",
          year: "yyyy",
        },
        tooltipDateFormat: "dd/MM/yyyy",
      })
    );

    const yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: am5xy.AxisRendererY.new(root, {
          inversed: true,
          minGridDistance: 20,
        }),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    const processData = () => {
      return data.flatMap((group) =>
        group.services.flatMap((service) =>
          service.gridItems.flatMap((gridItem) =>
            gridItem.isEmpty
              ? []
              : gridItem.subItems.map((subItem) => {
                  const start = new Date(subItem.beginDate).getTime();
                  let end = new Date(subItem.endDate).getTime();
                  if (start === end) end += 1;

                  return visualizationMode === "SL"
                    ? {
                        category: service.name,
                        start,
                        end,
                        description: subItem.location,
                        color: group.color,
                      }
                    : {
                        category: group.name,
                        start,
                        end,
                        description: service.name,
                        color: group.color,
                      };
                })
          )
        )
      );
    };

    const transformedData = processData();

    const uniqueCategories = [
      ...new Set(transformedData.map((item) => item.category).filter(Boolean)),
    ];
    yAxis.data.setAll(uniqueCategories.map((category) => ({ category })));
    setChartHeight(Math.min(Math.max(uniqueCategories.length * 30, 500), 2000));

    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: "end",
        openValueXField: "start",
        categoryYField: "category",
        sequencedInterpolation: true,
      })
    );

    series.columns.template.setAll({
      height: am5.percent(50),
      tooltipText:
        '{category}: {description}\nInício: [bold]{openValueX.formatDate("dd/MM/yyyy")}[/]\nFim: [bold]{valueX.formatDate("dd/MM/yyyy")}[/]',
      fillOpacity: 1,
      strokeOpacity: 0,
      minWidth: 5,
    });

    series.columns.template.adapters.add("width", (_, target) => {
      const dataItem = target.dataItem?.dataContext as any;
      if (dataItem && dataItem.start === dataItem.end) {
        return 5;
      }
      return undefined;
    });

    series.columns.template.adapters.add("fill", (_, target) => {
      const dataItem = target.dataItem?.dataContext as any;
      return dataItem?.color ? am5.color(dataItem.color) : am5.color("#cccccc");
    });

    series.columns.template.adapters.add("stroke", (_, target) => {
      const dataItem = target.dataItem?.dataContext as any;
      return dataItem?.color ? am5.color(dataItem.color) : am5.color("#cccccc");
    });

    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
      })
    );

    series.data.setAll(transformedData);

    series.appear(1000);
    chart.appear(1000, 100);

    return () => root.dispose();
  }, [data, visualizationMode]);

  return (
    <div
      ref={chartRef}
      style={{
        width: "100%",
        height: `${chartHeight}px`,
        overflowX: "auto",
      }}
    />
  );
};

export default GanttChart;
