import React from "react";
import styled from "styled-components";
import TodayIcon from "@mui/icons-material/Today";
import { Colors } from "../../../theme/v2/variants";
import Avatar from "@mui/material/Avatar";

interface LabeledItemCardProps {
  title: string;
  text: string | number;
  startIcon?: React.ReactNode;
  color?: string;
  fontColor?: string;
  fontSize?: string;
  fontWeight?: string | number;
  useAvatarStartIcon?: boolean;
  avatarBackgroundColor?: string;
  titleFontSize?: string;
  avatarSize?: number;
  avatarFontSize?: number;
  textWrap?: boolean;
  className?: string;
}

const CardContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div<{ color?: string }>`
  margin-right: 8px;
  color: ${(props) => props.color || Colors.greenLight};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

const Title = styled.div<{ color?: string; titleFontSize?: string }>`
  font-size: ${(props) => props.titleFontSize || "12px"};
  color: ${(props) => props.color || Colors.greenLight};
  font-weight: 500;
  align-self: flex-start;
`;

const Text = styled.div<{
  fontColor?: string;
  fontSize?: string;
  fontWeight: string | number;
  textWrap?: boolean;
}>`
  display: flex;
  color: ${(props) => props.fontColor || Colors.primaryColorDark};
  font-size: ${(props) => props.fontSize || "16px"};
  font-style: normal;
  font-weight: ${(props) => props.fontWeight};
  line-height: normal;
  white-space: ${(props) => (props.textWrap ? "normal" : "nowrap")};
  justify-content: start;
`;

const LabeledItemCard: React.FC<LabeledItemCardProps> = ({
  title,
  text,
  startIcon,
  color,
  fontColor,
  fontSize,
  fontWeight = 500,
  useAvatarStartIcon,
  avatarBackgroundColor,
  titleFontSize,
  avatarSize = 42,
  avatarFontSize,
  textWrap = false,
  className,
}) => {
  const getInitials = (name: string) => {
    const initials = name
      .split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase();
    return initials.slice(0, 2);
  };

  return (
    <CardContainer className={className}>
      {useAvatarStartIcon && typeof text === "string" && (
        <Avatar
          sx={{
            width: avatarSize,
            height: avatarSize,
            backgroundColor: avatarBackgroundColor || Colors.primaryColor,
            fontWeight: 700,
            fontSize: avatarFontSize || "initial",
            marginRight: 4,
          }}
        >
          {getInitials(text || "")}
        </Avatar>
      )}
      {startIcon ? <IconWrapper color={color}>{startIcon}</IconWrapper> : null}
      <TextContainer>
        <Title title={title} color={color} titleFontSize={titleFontSize}>
          {title}
        </Title>
        <Text
          title={String(text)}
          fontColor={fontColor}
          fontSize={fontSize}
          fontWeight={fontWeight}
          textWrap={textWrap}
        >
          {text}
        </Text>
      </TextContainer>
    </CardContainer>
  );
};

export default LabeledItemCard;
