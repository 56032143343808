import styled from "styled-components";
import ReactPageBaseComponent from "../../../components/BaseComponents/ReactPageBaseComponent";
import { Colors } from "../../../theme/v2/variants";
import { useDashboard } from "../../../contexts/dashboard";
import React, { useEffect, useMemo, useState } from "react";
import { BaseState } from "../../Actions/ViewActions/utils/BaseState";
import EmptyClientAndConstructionState from "../../../components/StateComponents/EmptyClientAndConstruction";
import LoadingComponent from "../../../components/StateComponents/LoadingComponent";
import ErrorComponent from "../../../components/StateComponents/ErrorComponent";
import { Snackbar, Tabs, Tab } from "@mui/material";
import { useDropzone } from "react-dropzone";
import AdvancementIndexReportComponent from "../components/AdvancementIndexReportComponent";
import { Line } from "react-chartjs-2";
import Subtitle from "../../../components/charts/Subtitle/Subtitle";
import VerticalService from "../../../services/VeritcalService";
import ConstructionService from "../../../services/ConstructionService";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";
import { VerticalServiceReport } from "../../../services/interfaces/VerticalServiceReport";
import { ServiceReportExportToPdf } from "../components/ServiceReportExportToPdf";
import ButtonComponent, {
  ButtonState,
  ButtonType,
} from "../../../components/v2/Button/ButtonComponent";
import DateFilter, {
  DateFilterType,
} from "../../Actions/ViewActions/DateFilter";
import { ReactComponent as Article } from "../../../assets/article.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as FilterOutlined } from "../../../assets/filter-outline.svg";
import Footer from "../../../components/v2/Footer";
import LabeledItemCard from "../../../components/Cards/LabeledItemCard/LabeledItemCard";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ConfirmDeleteItemPopUp from "../../../components/Dialogs/ConfirmDeleteItemPopUp";
import dayjs from "dayjs";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { TooltipItem } from "chart.js";
import { ArrowBackSharp } from "@mui/icons-material";
import { VERTICAL_SERVICE_PATH } from "../../../config/app-info";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import moment from "moment";
import {
  formatDateToDDMMYYYY,
  formatDateToISODateOnly,
} from "../../../services/utils/data";
import CustomDropDownComponent from "../../../components/InputValues/CustomDropDownComponent";
import DropDownComponent from "../../../components/InputValues/DropDownComponent";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

interface Props {}

const TabsEnum = {
  PHYSICAL: "Avanços físicos",
  EVOLUTION: "Evolução dos serviços",
  IMAGES: "Imagens",
  RESCHEDULING: "Reprogramações",
};

export const baseData = {
  labels: [],
  datasets: [
    {
      type: "line" as const,
      data: [],
      datalabels: {
        display: false,
      },
      borderColor: Colors.dullLavender,
      backgroundColor: Colors.dullLavender,
      pointRadius: 6,
      pointBackgroundColor: Colors.neutralColorWhite,
      pointBorderColor: Colors.dullLavender,
      pointBorderWidth: 3,
    },
    {
      type: "line" as const,
      data: [],
      datalabels: {
        display: false,
      },
      borderColor: Colors.sweetPink,
      backgroundColor: Colors.sweetPink,
      pointRadius: 6,
      pointBackgroundColor: Colors.neutralColorWhite,
      pointBorderColor: Colors.sweetPink,
      pointBorderWidth: 3,
    },
  ],
};

export const mixedChartData = {
  labels: [],
  datasets: [
    {
      type: "line" as const,
      data: [],
      borderColor: Colors.sweetPink,
      backgroundColor: Colors.sweetPink,
      pointRadius: 4,
      pointBackgroundColor: Colors.sweetPink,
      pointBorderColor: Colors.sweetPink,
      pointBorderWidth: 3,
      yAxisID: "percentAxis",
      datalabels: {
        align: "end" as any,
        anchor: "end" as any,
        formatter: (value: any) => {
          return Math.round(value) + "%";
        },
        clamp: true,
        labels: {
          title: {
            font: {
              weight: 500,
              size: 12,
            },
          },
        },
      },
      tooltip: {
        padding: 12,
        boxPadding: 8,
        bodyAlign: "center" as "center" | "left" | "right",
        callbacks: {
          label: (tooltipItem: TooltipItem<"line">) => {
            const { parsed } = tooltipItem;
            const currentValue = parsed.y.toFixed(2);

            return `${currentValue}%`;
          },
        },
      },
    },
    {
      type: "bar" as const,
      data: [],
      borderWidth: 1,
      borderColor: Colors.dullLavender,
      backgroundColor: Colors.dullLavender,
      yAxisID: "y",
      datalabels: {
        display: false,
      },
    },
  ],
};

export const PHYSICAL_PROGRESS = "Avanços físicos";
export const FINANCIAL_PROGRESS = "Avanços financeiros";
export const SERVICE_EVOLUTION = "Evolução dos serviços";
export const IMAGES = "Imagens";

const ServiceReportPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const verticalServiceService = new VerticalService();
  const constructionService = new ConstructionService();
  const { constructionId } = useDashboard();
  const [state, setState] = useState<BaseState>(BaseState.success);
  const [reportData, setReportData] = useState<VerticalServiceReport>();
  const [chartData, setChartData] = useState(baseData);
  const [mixedData, setMixedData] = useState(mixedChartData);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>();
  const [selectedTab, setSelectedTab] = React.useState(TabsEnum.PHYSICAL);
  const [filterItems, setFilterItems] = useState<
    { name: string; checked: boolean }[]
  >([
    {
      checked: true,
      name: PHYSICAL_PROGRESS,
    },
    {
      checked: true,
      name: FINANCIAL_PROGRESS,
    },
    {
      checked: true,
      name: SERVICE_EVOLUTION,
    },
    {
      checked: true,
      name: IMAGES,
    },
  ]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedDates, setSelectedDates] = useState<DateFilterType>();
  const [appliedDates, setAppliedDates] = useState<DateFilterType>();
  const [openDateFilterDialog, setOpenDateFilterDialog] =
    useState<boolean>(false);

  const [deletedImage, setDeletedImage] = useState<{
    serviceId: number;
    imageId: number;
  }>();
  const [isDeleteActionLoadingDialog, seDeleteActiontLoadingDialog] =
    useState<boolean>(false);

  const currentYear = new Date().getFullYear();
  const [periodText, setPeriodText] = useState<string>(currentYear.toString());

  const [visibleImages, setVisibleImages] = useState(3);

  const [totalBarData, setTotalBarData] = useState(0);

  const [sortBy, setSortBy] = useState("Data da imagem");

  const handleResize = () => {
    if (window.innerWidth < MOBILE_BREAKPOINT) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    if (isMobile === undefined) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);
  });

  function isConstructionNotSelected() {
    return (
      constructionId === null ||
      constructionId === undefined ||
      constructionId === 0
    );
  }

  const findReportData = async (
    constructionId: string,
    beginDate?: Date | unknown,
    endDate?: Date | unknown
  ) => {
    if (!constructionId) return;

    setState(BaseState.loading);
    try {
      const response =
        await verticalServiceService.getReportDataByConstructionId(
          constructionId,
          formatDateToISODateOnly(beginDate?.toString()),
          formatDateToISODateOnly(endDate?.toString())
        );

      handlePageData(response, beginDate?.toString(), endDate?.toString());
    } catch (error) {
      setState(BaseState.error);
    }
  };

  useEffect(() => {
    if (!constructionId) return;
    findReportData(constructionId?.toString());
  }, [constructionId]);

  const onSelectDateFilter = (dates: DateFilterType) => {
    setSelectedDates(dates);
    setOpenDateFilterDialog(false);

    const beginDate = dates?.startDate.toISOString().slice(0, 10);
    const endDate = dates?.deadlineDate.toISOString().slice(0, 10);

    findReportData(constructionId?.toString(), beginDate, endDate);
    setAppliedDates(dates);
  };

  const onCanceledDateFilter = () => {
    setOpenDateFilterDialog(false);
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const handlePageData = (
    data: VerticalServiceReport,
    beginDate?: Date | unknown,
    endDate?: Date | unknown
  ) => {
    setReportData(data);

    generatePeriodText(data, beginDate, endDate);

    const labelsForTable =
      data?.serviceProgressLabels?.map((date: any) => {
        const [month, year] = date.monthAsString.split(" de ");
        const capitalizedMonth = capitalizeFirstLetter(month);
        return `${capitalizedMonth}/${year}`;
      }) || [];

    if (isMobile) {
      (baseData.labels as string[]) = labelsForTable?.map((label: string) =>
        label.substring(0, 3)
      );
    } else {
      (baseData.labels as string[]) = labelsForTable;
    }

    (mixedChartData.labels as string[]) =
      data?.rescheduling?.map((item) => item.reschedulingReason) || [];

    (mixedChartData.datasets[0].data as any[]) = [];

    let accumulatedPercentage = 0;

    (mixedChartData.datasets[0].data as any[]).push(
      ...(data?.rescheduling?.map((item) => {
        accumulatedPercentage += item.percentage;
        return accumulatedPercentage;
      }) || [])
    );

    (mixedChartData.datasets[1].data as any[]) = [];
    (mixedChartData.datasets[1].data as any[]).push(
      ...(data?.rescheduling?.map((item) => item.count) || [])
    );

    const totalBarData = (mixedChartData.datasets[1].data as any[]).reduce(
      (sum, count) => sum + count,
      0
    );

    (baseData.datasets[0].data as any[]) = [];
    (baseData.datasets[0].data as any[]).push(
      ...(data?.concludedServices || [])
    );
    (baseData.datasets[1].data as any[]) = [];
    (baseData.datasets[1].data as any[]).push(
      ...(data?.unconcludedServices || [])
    );

    setChartData(baseData);
    setMixedData(mixedChartData);
    setTotalBarData(totalBarData);
    setState(BaseState.success);
  };

  const generatePeriodText = (
    data: VerticalServiceReport,
    beginDate: Date | unknown,
    endDate: Date | unknown
  ) => {
    if (beginDate && endDate) {
      const formattedStartDate = formatDateToDDMMYYYY(String(beginDate));
      const formattedEndDate = formatDateToDDMMYYYY(String(endDate));

      const periodText = `${formattedStartDate} - ${formattedEndDate}`;
      setPeriodText(periodText);
      return;
    }

    if (data?.construction?.startDate && data?.construction?.endDate) {
      const formattedStartDate = formatDateToDDMMYYYY(
        data.construction.startDate
      );
      const formattedEndDate = formatDateToDDMMYYYY(data.construction.endDate);

      const periodText = `${formattedStartDate} - ${formattedEndDate}`;
      setPeriodText(periodText);
      return;
    }

    if (data?.serviceProgressLabels && data.serviceProgressLabels.length > 0) {
      const firstLabel = data.serviceProgressLabels[0];
      const lastLabel =
        data.serviceProgressLabels[data.serviceProgressLabels.length - 1];

      const startDate = dayjs(firstLabel.startDate);
      const endDate = dayjs(lastLabel.endDate);

      const formattedStartDate = startDate.format("DD/MM/YYYY");
      const formattedEndDate = endDate.format("DD/MM/YYYY");

      const periodText = `${formattedStartDate} - ${formattedEndDate}`;
      setPeriodText(periodText);
      return;
    }

    console.error("Não foi possível determinar o período da obra.");
  };

  const onDrop = (acceptedFiles: any, fileRejections: any) => {
    if (fileRejections.length > 0) {
      return;
    }
    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append("file", file);
    constructionService
      .updateImage(constructionId?.toString(), formData)
      .then((response) => {
        if (!reportData?.construction) {
          return;
        }
        setReportData({
          ...reportData,
          construction: {
            ...reportData?.construction,
            imageUrl: response,
          },
        });
      })
      .catch(() => {
        setSnackbarMessage("Erro ao atualizar a imagem");
        setOpenSnackbar(true);
      });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/svg+xml": [".svg"],
    },
    maxFiles: 1,
  });

  const options = {
    responsive: true,
    maintainAspectRatio: isMobile ? false : true,
    plugins: {
      dataLabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "top" as const,
      },
      title: {
        display: false,
      },
      tooltip: {
        yAlign: "bottom" as "bottom" | "top" | "center",
        padding: 12,
        displayColors: true,
        boxPadding: 8,
        bodyAlign: "center" as "center" | "left" | "right",
        callbacks: {
          title: () => "",
          label: (tooltipItem: TooltipItem<"line">) => {
            const { parsed } = tooltipItem;
            const currentValue = parsed.y.toFixed(2);

            return `${currentValue}%`;
          },
        },
      },
    },
  };

  const mixedChartOptions = {
    responsive: true,
    scales: {
      y: {
        display: true,
        max: totalBarData,
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
      percentAxis: {
        display: false,
        beginAtZero: true,
        max: 112,
        clamp: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const handleOnTabChange = (newValue: string) => {
    setSelectedTab(newValue);
  };

  const goToVertical = () => {
    navigate(VERTICAL_SERVICE_PATH);
  };

  const deleteImageOnClick = (serviceId: number, imageId: number) => {
    setDeletedImage({ serviceId, imageId });
  };

  const confirgDeleteImageOnClick = () => {
    const serviceId = deletedImage?.serviceId;
    const imageId = deletedImage?.imageId;
    if (serviceId && imageId) {
      verticalServiceService
        .deleteImage(serviceId, imageId)
        .then(() => {
          setDeletedImage(undefined);
          findReportData(constructionId?.toString());
          setSnackbarMessage("Imagem excluída com sucesso");
          setOpenSnackbar(true);
        })
        .catch(() => {
          setSnackbarMessage("Erro ao excluir imagem");
          setOpenSnackbar(true);
        });
    } else {
      setSnackbarMessage("Erro ao excluir imagem");
      setOpenSnackbar(true);
    }
  };

  const cancelDeleteImageOnClick = () => {
    setDeletedImage(undefined);
  };

  const loadMoreImages = () => {
    const totalImages =
      reportData?.images.reduce(
        (sum, serviceGroup) => sum + serviceGroup.imageUrl.length,
        0
      ) || 0;

    setVisibleImages(totalImages);
  };

  const cleanDataFilter = () => {
    setOpenDateFilterDialog(false);
    setSelectedDates(undefined);
    setAppliedDates(undefined);
    findReportData(constructionId?.toString());
  };

  const handleSortChange = (value: string) => {
    setSortBy(value);
  };

  const sortedImages = useMemo(() => {
    const imagesCopy = reportData?.images ?? [];

    const getDate = (image: any, field: string) => {
      field === "Data da imagem"
        ? (field = "createdAt")
        : (field = "serviceBeginDate");
      if (Array.isArray(image[field]) && image[field][0]) {
        return new Date(image[field][0]).getTime();
      }
      return 0;
    };

    const sorted = imagesCopy.sort((a, b) => {
      const dateA = getDate(a, sortBy);
      const dateB = getDate(b, sortBy);

      return dateB - dateA;
    });

    return sorted;
  }, [reportData?.images, sortBy]);

  return (
    <ReactPageBaseComponent>
      <PageContainer>
        {isConstructionNotSelected() ? (
          <CenterContainer>
            <EmptyClientAndConstructionState pageDescription="o relatório de servicos" />{" "}
          </CenterContainer>
        ) : null}

        {state === BaseState.loading && !isConstructionNotSelected() ? (
          <CenterContainer>
            <LoadingComponent />
          </CenterContainer>
        ) : null}

        {state === BaseState.error ? (
          <CenterContainer>
            <ErrorComponent />
          </CenterContainer>
        ) : null}

        {state === BaseState.success && !isConstructionNotSelected() ? (
          <SectionsContainer>
            <PageConfigContainer>
              <HeaderSection>
                <ButtonComponent
                  text={"Voltar"}
                  type={ButtonType.TRANSPARENT}
                  startIcon={<ArrowBackSharp />}
                  onClick={() => goToVertical()}
                />
                <PageTitle>Índices de Avanços</PageTitle>
                <ButtonActionsContainer>
                  {!isMobile && (
                    <ExportPdfAndFilterContainer>
                      <ButtonComponent
                        type={ButtonType.OUTLINE}
                        text={"Vertical de Serviços"}
                        startIcon={<Article />}
                        onClick={() => goToVertical()}
                        padding={{ top: 10, bottom: 10, left: 14, right: 20 }}
                      />
                      <ButtonComponent
                        type={ButtonType.OUTLINE_SELECTED}
                        startIcon={<Article />}
                        onClick={() => {}}
                        text={isMobile ? "" : "Relatório"}
                        padding={{ top: 10, bottom: 10, left: 14, right: 20 }}
                      />
                      <ServiceReportExportToPdf
                        chartData={chartData}
                        reschedulingChartData={mixedData}
                        reschedulingChartOptions={mixedChartOptions}
                        reportData={reportData}
                        filterItems={filterItems}
                        reportDates={periodText}
                        sortedImages={sortedImages}
                      />
                      <ButtonComponent
                        text={
                          !selectedDates
                            ? "Filtrar"
                            : selectedDates?.startDate?.toLocaleDateString(
                                "pt-BR",
                                {
                                  day: "2-digit",
                                  month: "2-digit",
                                }
                              ) +
                              " à " +
                              selectedDates?.deadlineDate?.toLocaleDateString(
                                "pt-BR",
                                {
                                  day: "2-digit",
                                  month: "2-digit",
                                }
                              )
                        }
                        type={ButtonType.OUTLINE}
                        startIcon={<FilterOutlined />}
                        padding={{ top: 10, bottom: 10, left: 14, right: 20 }}
                        onClick={() => setOpenDateFilterDialog(true)}
                      />
                    </ExportPdfAndFilterContainer>
                  )}
                </ButtonActionsContainer>
              </HeaderSection>
              <TabContainer>
                <Tabs
                  value={selectedTab}
                  onChange={(event, value) => handleOnTabChange(value)}
                  style={{ width: "100%", minHeight: 0 }}
                  sx={{
                    "& .MuiTabs-indicator": {
                      display: "none",
                    },
                  }}
                >
                  <CustomTab
                    value={TabsEnum.PHYSICAL}
                    label="Avanços físicos"
                  />
                  <CustomTab
                    value={TabsEnum.EVOLUTION}
                    label="Evolução dos serviços"
                  />
                  <CustomTab
                    value={TabsEnum.RESCHEDULING}
                    label="Causas de reprogramação"
                  />
                  <CustomTab
                    value={TabsEnum.IMAGES}
                    label="Relatório Fotográfico"
                  />
                </Tabs>
              </TabContainer>
            </PageConfigContainer>
            <IndiceContainer>
              {selectedTab === TabsEnum.PHYSICAL && (
                <IndiceSubContainer>
                  {reportData?.physicalProgress.map((indice) => (
                    <AdvancementIndexReportComponent
                      title={indice.groupName}
                      value={indice.progressAsPercent}
                      serviceTypes={indice.serviceTypes}
                      isTotal={indice.groupName === "Total"}
                    />
                  ))}
                </IndiceSubContainer>
              )}

              {selectedTab === TabsEnum.EVOLUTION && (
                <IndiceEvolutionSubContainer>
                  <PeriodText>{periodText}</PeriodText>
                  <ChartSubtitleContainer>
                    <SubtitleGroup>
                      <Subtitle
                        color={Colors.dullLavender}
                        text="Físico Real acumulado"
                      />
                      <Subtitle
                        color={Colors.sweetPink}
                        text="Físico Planejado acumulado"
                      />
                    </SubtitleGroup>
                    <ChartHelperText>
                      <b>Dica:</b> Para ver o valor no detalhe, passe o mouse
                      sobre os pontos
                    </ChartHelperText>
                  </ChartSubtitleContainer>
                  <ChartWhiteContainer>
                    <ChartContainer>
                      <Chart
                        type="bar"
                        redraw={true}
                        options={options}
                        data={chartData}
                      />
                    </ChartContainer>
                  </ChartWhiteContainer>
                </IndiceEvolutionSubContainer>
              )}

              {selectedTab === TabsEnum.RESCHEDULING && (
                <IndiceEvolutionSubContainer>
                  <PeriodText>{periodText}</PeriodText>
                  <ChartSubtitleContainer>
                    <SubtitleGroup>
                      <Subtitle
                        color={Colors.dullLavender}
                        text="Quantidade de ocorrências"
                      />
                      <Subtitle color={Colors.sweetPink} text="% acumulada" />
                    </SubtitleGroup>
                    <ChartHelperText>
                      <b>Dica:</b> Para ver o valor no detalhe, passe o mouse
                      sobre os pontos
                    </ChartHelperText>
                  </ChartSubtitleContainer>
                  <ChartWhiteContainer>
                    <ChartContainer>
                      <MixedChart
                        type="bar"
                        redraw={true}
                        options={mixedChartOptions}
                        data={mixedData}
                      />
                    </ChartContainer>
                  </ChartWhiteContainer>
                </IndiceEvolutionSubContainer>
              )}
            </IndiceContainer>

            {selectedTab === TabsEnum.IMAGES && (
              <>
                <SortReportContainer>
                  <DropDownContainer>
                    <DropDownComponent
                      values={["Data da imagem", "Data do serviço"]}
                      key="1"
                      componentKey="sort"
                      variant="filled"
                      label="Ordenar por"
                      defaultValue="Ordenar por"
                      onSelectedValueChanged={(key, value) =>
                        handleSortChange(value)
                      }
                      bakcgrounColor={Colors.neutralColorWhite}
                    />
                  </DropDownContainer>
                </SortReportContainer>
                <ImagesSection>
                  {sortedImages && sortedImages.length > 0
                    ? sortedImages.reduce<React.ReactNode[]>(
                        (acc, serviceType) => {
                          const remainingSlots = visibleImages - acc.length;

                          if (remainingSlots <= 0) return acc;

                          const imagesToShow = serviceType.imageUrl.slice(
                            0,
                            remainingSlots
                          );

                          const renderedImages = imagesToShow.map(
                            (image, index) => (
                              <ImageListItem key={serviceType.imageId[index]}>
                                <ImageListContainer>
                                  <ServiceImage
                                    crossOrigin="anonymous"
                                    src={image}
                                    alt="Imagem do serviço"
                                  />
                                </ImageListContainer>
                                <ImageListDescription>
                                  <ImageListDescriptionFirstLine>
                                    <LimitedWidthLabeledItemCard
                                      title="Serviço"
                                      text={serviceType.name}
                                      fontWeight={600}
                                      textWrap
                                    />
                                    <LabeledItemCard
                                      title="Data"
                                      text={dayjs(
                                        serviceType.createdAt[index]
                                      ).format("DD/MM/YYYY")}
                                      fontWeight={600}
                                    />
                                  </ImageListDescriptionFirstLine>
                                  {serviceType.lastUpdatedByUser[index] !==
                                  "Usuário desconhecido" ? (
                                    <ImageListDescriptionUserLine>
                                      <LabeledItemCard
                                        title="Atualizado por"
                                        text={
                                          serviceType.lastUpdatedByUser[index]
                                        }
                                        fontWeight={600}
                                        useAvatarStartIcon
                                        avatarBackgroundColor={Colors.stTropaz}
                                      />
                                    </ImageListDescriptionUserLine>
                                  ) : null}
                                </ImageListDescription>
                                <ImageListActionContainer>
                                  <ButtonComponent
                                    type={ButtonType.PRIMARY}
                                    text="Remover"
                                    startIcon={<DeleteOutlineIcon />}
                                    onClick={() => {
                                      deleteImageOnClick(
                                        serviceType.id,
                                        serviceType.imageId[index]
                                      );
                                    }}
                                    padding={{
                                      top: 8,
                                      bottom: 8,
                                      left: 20,
                                      right: 20,
                                    }}
                                  />
                                </ImageListActionContainer>
                              </ImageListItem>
                            )
                          );

                          return [...acc, ...renderedImages];
                        },
                        []
                      )
                    : null}
                </ImagesSection>

                <LoadImagesContainer>
                  <ButtonComponent
                    type={ButtonType.PRIMARY}
                    text="Carregar imagens"
                    startIcon={<AutorenewIcon />}
                    onClick={loadMoreImages}
                    padding={{ top: 12, bottom: 12, left: 20, right: 20 }}
                    state={
                      visibleImages >=
                      (reportData?.images.reduce(
                        (sum, group) => sum + group.imageUrl.length,
                        0
                      ) || 0)
                        ? ButtonState.DISABLED
                        : ButtonState.DEFAULT_ENABLED
                    }
                  />
                </LoadImagesContainer>
              </>
            )}
          </SectionsContainer>
        ) : null}
        <Footer />
      </PageContainer>
      <DateFilter
        openDialog={openDateFilterDialog}
        dates={selectedDates}
        onConfirmDialogCallback={onSelectDateFilter}
        onCancelDialogCallback={() => onCanceledDateFilter()}
        cleanFilter={() => cleanDataFilter()}
        doNotChangeEndDateWhenChangeBeginDate
      />
      <Snackbar
        message={snackbarMessage}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => {
          setOpenSnackbar(false);
        }}
      />
      <ConfirmDeleteItemPopUp
        title="Excluir imagem"
        description="Deseja realmente excluir esta imagem?"
        openDeleteActionDialog={deletedImage !== undefined}
        isLoadingDialog={isDeleteActionLoadingDialog}
        dialogButtonCancelOnClick={() => cancelDeleteImageOnClick()}
        dialogButtonConfirmOnClick={confirgDeleteImageOnClick}
      />
    </ReactPageBaseComponent>
  );
};

const LimitedWidthLabeledItemCard = styled(LabeledItemCard)`
  max-width: 200px;
`;

const DropDownContainer = styled.div`
  width: 20%;
  margin-right: 32px;
`;

const SortReportContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 16px;
`;

const MixedChart = styled(Chart)`
  overflow: visible;
  overflow-clip-margin: auto;
`;

const LoadImagesContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 59px;
`;

const ImageListActionContainer = styled.div`
  display: grid;
  justify-content: end;
  align-items: end;
`;

const ImageListDescriptionUserLine = styled.div`
  border-bottom: 1px solid rgba(178, 178, 178, 0.25);
  padding-bottom: 16px;
`;

const ImageListDescriptionFirstLine = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(178, 178, 178, 0.25);
`;

const PeriodText = styled.div`
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  color: ${Colors.neutralGrayDark};
  text-align: end;
`;

const CustomTab = styled((props) => <Tab {...props} />)(() => ({
  maxWidth: "100%",
  backgroundColor: Colors.neutralColorWhite,
  color: `${Colors.primaryColor}`,
  fontFamily: "Noto Sans",
  fontSize: 16,
  fontStyle: "normal",
  lineHeight: "normal",
  fontWeight: 400,
  paddingTop: "13px !important",
  paddingBottom: "13px !important",
  paddingLeft: "20px !important",
  paddingRight: "20px !important",
  minHeight: 0,
  minWidth: 0,
  borderTopLeftRadius: "16px",
  borderTopRightRadius: "16px",
  margin: "0 6px",
  "&.Mui-selected": {
    color: `${Colors.primaryColorDark}`,
    backgroundColor: `${Colors.neutralColorSurface}`,
    fontWeight: 700,
    borderTop: `1px solid ${Colors.lightMercury}`,
    borderLeft: `1px solid ${Colors.lightMercury}`,
    borderRight: `1px solid ${Colors.lightMercury}`,
  },
  "&:last-child": {
    marginRight: 0,
  },
  "&:first-child": {
    marginLeft: 0,
  },
}));

const ChartSubtitleContainer = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: space-between;
  align-items: center;
`;

const ChartHelperText = styled.div`
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: ${Colors.gray};
`;

const TabContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

const PageConfigContainer = styled.div`
  background-color: ${Colors.neutralColorWhite};
  width: 100%;
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  border-bottom: 1px solid ${Colors.lightMercury};
`;

const ExportPdfAndFilterContainer = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
  column-gap: 12px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: max-content max-content max-content;
    place-self: end;
  }
`;

const ButtonActionsContainer = styled.div`
  display: grid;
  justify-content: end;
  grid-template-rows: max-content max-content max-content;
`;

const PageTitle = styled.div`
  font-family: "Noto Sans";
  font-weight: 700;
  font-size: 24px;
  color: ${Colors.primaryColor};
  margin-left: 10px;
`;

const PageContainer = styled.div`
  display: grid;
  width: auto;
  height: auto;
  grid-template-columns: 1fr;
  grid-template-rows: auto, auto;
  background-color: ${Colors.neutralColorSurface};
  overflow: visible;
  position: relative;
  align-content: start;
`;

const CenterContainer = styled.div`
  display: grid;
  height: 85vh;
  align-content: center;
`;

const SectionsContainer = styled.div`
  display: grid;
  grid-template-rows: min-content min-content min-content min-content min-content;
  padding-bottom: 32px;
`;

const HeaderSection = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  margin-bottom: 35px;
`;

const IndiceContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: max-content;
  margin-top: 24px;
  padding-left: 32px;
  padding-right: 32px;
`;

const IndiceSubContainer = styled.div`
  background-color: ${Colors.neutralColorWhite};
  display: grid;
  width: 100%;
  grid-template-rows: auto auto;
  row-gap: 32px;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid rgba(178, 178, 178, 0.25);
`;

const IndiceEvolutionSubContainer = styled.div`
  background-color: ${Colors.neutralColorWhite};
  display: grid;
  width: 100%;
  grid-template-rows: auto auto;
  padding: 30px 78px;
  border-radius: 16px;
  border: 1px solid rgba(178, 178, 178, 0.25);
`;

const ChartWhiteContainer = styled.div`
  display: grid;
  max-width: 100%;
  background-color: #fff;
  overflow-x: hidden;
  justify-items: center;
`;

const ChartContainer = styled.div`
  display: grid;
  height: 476.5px;
  width: 100%;
  margin-top: 40px;
  justify-items: center;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    max-height: fit-content;
    max-width: 100%;
    overflow: hidden;
    justify-items: center;
    margin-top: 16px;
  }
`;

const SubtitleGroup = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
`;

const ImagesSection = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  padding-left: 32px;
  padding-right: 32px;
  gap: 24px;
`;

const ImageListItem = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: min-content min-content;
  background-color: ${Colors.neutralColorWhite};
  gap: 18px;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid rgba(178, 178, 178, 0.25);
  -webkit-box-shadow: 0px 30px 30px -30px rgba(59, 66, 90, 0.5);
  -moz-box-shadow: 0px 30px 30px -30px rgba(59, 66, 90, 0.5);
  box-shadow: 0px 30px 30px -30px rgba(59, 66, 90, 0.5);
`;

const ImageListContainer = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 16px;
  align-content: center;
  justify-content: center;
`;

const ImageListDescription = styled.div`
  display: grid;
  color: ${Colors.textColorBlack};
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  gap: 12px;
`;

const ServiceImage = styled.img`
  width: 100%;
  height: 312px;
  object-fit: cover;
`;

export default ServiceReportPage;
