import styled from "styled-components";
import { Colors } from "../../theme/v2/variants";
import { MOBILE_BREAKPOINT } from "../../utils/mobileDimen";
import Tab from "@mui/material/Tab";
import { Button, IconButton, Menu, MenuItem, Switch } from "@mui/material";
import React from "react";
import typography from "../../theme/v2/typography";

export const GroupByTimeText = styled.span`
  font-size: 14px;
  font-family: "Noto Sans";
  font-weight: 500;
  color: ${Colors.neutralColorWhite};
  word-wrap: break-word;

  text-align: end;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 12px;
  }
`;

export const FullItemContainer = styled.div<{ columnQuantity: number }>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.columnQuantity > 7
      ? `repeat(${props.columnQuantity}, calc((100vw - 491px) / 7))`
      : `repeat(${props.columnQuantity}, calc((100vw - 491px) / ${props.columnQuantity}))`};
  grid-template-columns: repeat(${(props) => props.columnQuantity}, 1fr);
  max-width: 100%;
  background: ${Colors.wildSand};
`;

export const PageContainer = styled.div<{ isNavBarHide: boolean }>`
  display: grid;
  width: 100%;
  height: 100%;
  min-height: 100%;
  grid-template-columns: 1fr;
  background-color: ${Colors.neutralColorSurface};
  overflow-x: hidden;
  align-content: space-between;
`;

export const ContentContainer = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
  grid-template-columns: 1fr;
  max-width: 100%;
  height: 100%;
  flex-grow: 1;
`;

export const HeaderContainer = styled.div`
  display: grid;
  max-width: 100%;
  padding: 41px;
  align-items: center;
  background-color: ${Colors.primaryColorLight};

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr;
    align-content: start;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-rows: max-content max-content;
    padding: 20px;
  }
`;

export const AboutContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
`;

export const PrintStatePageDescriptionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content max-content;
  align-content: start;
  margin-top: 16px;
  /* margin-right: 32px; */
  row-gap: 12px;
  justify-self: flex-end;
`;

export const PrintStatePageDescriptionText = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${Colors.neutralColorWhite};
`;

export const AdvancementContainer = styled.div`
  margin-left: 32px;
  margin-right: 32px;
`;

export const ButtonActionsContainer = styled.div`
  display: flex;
  gap: 12px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 80%;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
  }
`;

export const PageDescription = styled.div`
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${Colors.neutralGrayDark};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 20px 0px;
    text-align: center;
  }
`;

export const TableContainer = styled.div`
  display: grid;
  height: max-content;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
  overflow-y: visible;
  margin-bottom: 120px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    /* margin-top: 24px; */
    /* margin-left: 20px;
    margin-right: 20px; */
    width: 150vw;
  }
`;

export const SliderContainer = styled.div`
  max-width: 100%;
  overflow: visible;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    overflow-x: scroll;
  }
`;

export const TimeRangeContainer = styled.div`
  padding-left: 40px;
  padding-right: 30px;
  background-color: white;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const VerticalListRoot = styled.div<{
  columnQuantity: number;
  tableLength: number;
}>`
  display: grid;
  background-color: ${Colors.neutralColorWhite};
  grid-template-columns: ${(props) =>
    `240px repeat(${props.columnQuantity}, 1fr)`};
  border-bottom: 1px solid ${Colors.lightMercury};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 240px repeat(${(props) => props.columnQuantity}, 1fr);
  }
`;

export const HorizontalListPrimaryContainer = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;
  z-index: 98;
  min-width: 100%;
  padding-bottom: 20px;
`;

export const TimePeriodFilterDropDownContainer = styled.div`
  margin-top: 20px;
  background-color: ${Colors.neutralColorSurface};
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;

export const TableHeader = styled.div`
  background-color: ${Colors.neutralColorWhite};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 32px;
  padding-bottom: 35px;
  overflow: auto;

  @media (max-width: 1300px) {
    flex-direction: column-reverse;
    gap: 24px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0px;
    padding-bottom: 35px;
  }
`;

export const TabContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
      display: none;
  }
`;

export const TableControllerComponent = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: end;
  column-gap: 14px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: max-content max-content max-content max-content;
  }
`;

export const CustomTab = styled((props) => <Tab {...props} />)(() => ({
  backgroundColor: `${Colors.primaryColor}`,
  color: `${Colors.neutralColorWhite}`,
  fontFamily: "Noto Sans",
  fontSize: 14,
  fontStyle: "normal",
  lineHeight: "normal",
  fontWeight: 600,
  paddingTop: "8px !important",
  paddingBottom: "8px !important",
  paddingLeft: "20px !important",
  paddingRight: "20px !important",
  minHeight: 0,
  minWidth: 0,
  borderRadius: 70,
  margin: "0 6px",
  "&.Mui-selected": {
    color: `${Colors.neutralColorWhite}`,
    backgroundColor: `${Colors.dullLavender}`,
  },
  "&:last-child": {
    marginRight: 0,
  },
  "&:first-child": {
    marginLeft: 0,
  },
}));

// eslint-disable-next-line prettier/prettier
export const HorizontalListContainer = styled.div<{
  gridTemplate: string;
  tableLength: number;
}>`
  display: grid;
  grid-template-rows: max-content max-content;
  grid-template-columns: 240px ${(props) => props.gridTemplate};
  margin-left: 40px;
  margin-right: 30px;
  margin-top: 33px;
  border-radius: 10px;
  border: ${(props) =>
    props.tableLength === 0 ? `0` : `1px solid rgba(178, 178, 178, 0.5)`};
  border-bottom: 0;

  & > :first-child {
    border-top-left-radius: 10px;

    & + div > :last-child {
      border-top-right-radius: 10px;
    }
  }

  & > :nth-last-child(2) {
    border-bottom-left-radius: 10px;
  }

  & > :last-child > :last-child {
    border-bottom-right-radius: 10px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

export const EmptyGridItem = styled.div<{ isPrintState: boolean }>`
  /* ${({ isPrintState }) =>
    !isPrintState &&
    `
    width: 183px;
  `}

  ${({ isPrintState }) =>
    isPrintState &&
    `
    max-width: 183px;
  `} */

  width: 240px;

  height: 52px;
  background: ${Colors.neutralColorWhite};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 240px;
  }
`;

export const ItemHeaderGridContainerEmpty = styled.div<{
  isPrintState: boolean;
}>`
  ${({ isPrintState }) =>
    !isPrintState &&
    `
    width: 100%;
  `}
  ${({ isPrintState }) =>
    isPrintState &&
    `
    max-width: width: 100%;
  `}

  height: 52px;
  background: ${Colors.wildSand};
  border-left: 1px rgba(178, 178, 178, 0.5) solid;
  border-bottom: 1px rgba(178, 178, 178, 0.5) solid;
  /* min-width: 135px; */
  min-width: 80px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 135px;
  }
`;

export const ItemHeadTypeIndicator = styled.div`
  width: 100%;
  height: 52px;
  background: ${Colors.linkWater};
  display: grid;
  justify-content: left;
  align-content: center;
  font-size: 14px;
  font-family: "Noto Sans";
  font-weight: 600;
  word-wrap: break-word;
  padding-left: 20px;
  border-bottom: 1px rgba(178, 178, 178, 0.5) solid;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-top: 4px;
    padding-bottom: 4px;
  }
`;

export const ItemHeadServiceIndicator = styled.div`
  width: 100%;
  height: 52px;
  background: ${Colors.neutralColorWhite};
  border-bottom: 1px rgba(178, 178, 178, 0.5) solid;
  font-size: 14px;
  font-family: "Noto Sans";
  font-weight: 600;
  word-wrap: break-word;
  padding-left: 16px;
  display: grid;
  justify-content: left;
  align-content: center;
`;

export const ItemVerticalListHeader = styled.div`
  width: 100%;
  height: 52px;
  font-size: 16px;
  font-family: "Noto Sans";
  font-weight: 400;
  word-wrap: break-word;
  display: grid;
  justify-content: center;
  align-content: center;
`;

export const FullItemEmptyContainer = styled.div``;

// eslint-disable-next-line prettier/prettier
export const FullItemEmptyContainerCustomGrid = styled(FullItemEmptyContainer)<{
  gridTemplate: string;
}>`
  display: grid;
  grid-template-columns: ${(props) => props.gridTemplate};
  background: #f8f9fc;
  height: 52px;
  width: 100%;
`;

export const CenterContainer = styled.div`
  display: grid;
  height: 85vh;
  align-content: center;
`;

export const ExportPdfAndFilterContainer = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
  column-gap: 12px;
  background-color: blue;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
  }
`;

export const AddNewServiceButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-self: end;
  gap: 24px;
`;

export const QuadroAcompanhamento = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
  width: 50%;
  text-align: center;
  align-self: center;
  justify-self: center;
  margin-top: 0px;
  background-color: ${Colors.neutralColorWhite};
  border-radius: 16px;
  padding: 24px 54px;
  border: 1px solid rgba(178, 178, 178, 0.5);
  box-shadow: 0px 30px 30px -30px rgba(0, 0, 0, 0.25);
  margin-top: 30px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-left: 24px;
    padding-right: 24px;
    width: 90%;
  }
`;

export const Texto = styled.span`
  color: ${Colors.neutralGrayDark};
  font-size: 12px;
  font-family: "Noto Sans";
  word-wrap: break-word;
  font-weight: 500;
`;

export const PrintStateButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: grid;
  justify-content: flex-end;
  align-items: center;
  grid-template-columns: 110px 110px;
  column-gap: 24px;
  height: 98px;
  width: calc(100% - 135px);
  padding-right: 108px;
  background-color: ${Colors.neutralColorWhite};
  z-index: 99999;
`;

export const LastUpdateText = styled.span`
  font-size: 12px;
  font-family: "Noto Sans";
  font-weight: 400;
  color: ${Colors.neutralColorWhite};
`;

export const LastUpdateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export const GroupByTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

export const IconButtonActionMenu = styled(IconButton)`
  width: 40px;
  height: 40px;
  border: solid;
  border-radius: 50%;
  border-width: 1px;
  border-color: ${Colors.neutralGrayLight};
`;

export const PageTitle = styled.div`
  font-size: 16px;
  font-family: "Noto Sans";
  font-weight: 400;
  word-wrap: break-word;
  line-height: 21.79px;
  color: ${Colors.neutralColorWhite};
`;

export const ConstructionNameTitle = styled.div`
  font-size: 32px;
  font-family: "Noto Sans";
  font-weight: 700;
  word-wrap: break-word;
  line-height: 43.58px;
  color: ${Colors.neutralColorWhite};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 80%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 26px;
    width: 70%;
  }
`;

export const ConstructionImage = styled.img`
  background-color: ${Colors.neutralColorWhite};
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: block;
  object-fit: cover;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 50px;
    height: 50px;
  }
`;

export const TimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 20px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 40px;
    margin-top: 20px;
  }
`;

export const SwitchTime = styled(Switch).attrs({
  focusVisibleClassName: ".Mui-focusVisible",
  disableRipple: true,
})`
  width: 52px;
  height: 32px;
  padding: 0;

  & .MuiSwitch-switchBase {
    padding: 0;
    margin: 5px;
    transition-duration: 300ms;

    &.Mui-checked {
      transform: translateX(20px);
      color: ${Colors.neutralColorWhite};

      & + .MuiSwitch-track {
        background-color: ${Colors.greenLight};
        opacity: 1;
        border: 0;
      }

      &.Mui-disabled + .MuiSwitch-track {
        opacity: 0.5;
      }
    }

    &.Mui-focusVisible .MuiSwitch-thumb {
      color: #33cf4d;
      border: 6px solid ${Colors.neutralColorWhite};
    }

    &.Mui-disabled .MuiSwitch-thumb {
      color: ${Colors.butterflyBush};
    }

    &.Mui-disabled + .MuiSwitch-track {
      opacity: 0.7;
    }
  }

  & .MuiSwitch-thumb {
    box-sizing: border-box;
    width: 22px;
    height: 22px;
  }

  & .MuiSwitch-track {
    border-radius: 100px;
    background-color: ${Colors.butterflyBush};
    opacity: 1;
    transition: 0.5s;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 4px;
  margin-left: 14px;
`;

export const DescriptionContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: 32px;
  padding-right: 32px;
  align-items: center;
  margin-top: 22px;
  margin-bottom: 22px;
`;

export const StyledMenu = styled(Menu)`
  margin-top: 8px;
  .MuiPaper-root {
    background-color: ${Colors.neutralColorWhite};
    -webkit-box-shadow: 0px 25px 34px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 25px 34px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 25px 34px 0px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 8px;
    border: 1px solid rgba(178, 178, 178, 0.5);
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  color: ${Colors.primaryColorLight};
  font-family: "Noto Sans";
  font-size: 14px;
  font-weight: 400;
  gap: 10px;
  padding: 14px 16px;
`;

export const PeriodContainer = styled.div`
  display: flex;
  font-size: 12px;
  font-family: "Noto Sans";
  font-weight: 400;
  color: ${Colors.waterloo};
  margin-top: 16px;
`;

export const PeriodBold = styled.span`
  font-size: 12px;
  font-family: "Noto Sans";
  font-weight: 700;
  color: ${Colors.waterloo};
`;

export const PhysicalContainer = styled.div`
  margin-bottom: 14px;
`;

export const WhiteContainer = styled.div`
  background-color: ${Colors.neutralColorWhite};
`;

export const HoverButton = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${Colors.primaryColorLight};
  font-size: 14px;
  font-weight: 400;
  padding: 14px 16px;
`;

export const TextButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
`;

export const MarginRightContainer = styled.div`
  margin-right: 32px;
  justify-self: end;
`;

export const ButtonEndAlignWrapper = styled.div`
  justify-self: end;
`;

export const ConstructionImagePlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.primaryDarkBlue};
  color: ${Colors.secondaryColorLight};
  min-width: 70px;
  min-height: 70px;
  border-radius: 50%;
  object-fit: cover;
  font-size: ${typography.h1?.fontSize};
  font-weight: ${typography.fontWeightBold};
  text-transform: uppercase;
  user-select: none;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 50px;
    height: 50px;
  }
`;

export const TableActionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  z-index: 1;
`;

export const GanttChartContainer = styled.div`
  background-color: ${Colors.neutralColorWhite};
  display: grid;
  grid-template-rows: auto auto;
  padding: 30px 78px;
  margin-top: 30px;
  margin-left: 32px;
  margin-right: 32px;
  border-radius: 16px;
  border: 1px solid rgba(178, 178, 178, 0.25);
`;

export const GanttItem = styled.div<{
  isToday?: boolean;
}>`
  background-color: ${({ isToday }) => (isToday ? '#FFD700' : '#007bff')};
  border-radius: 4px;
  padding: 4px;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const GanttItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;