import LoadingButton from '@mui/lab/LoadingButton';
import { jsPDF } from 'jspdf';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDashboard } from '../../../../contexts/dashboard';
import ConstructionService from '../../../../services/ConstructionService';
import VerticalServiceGroup from '../../../../services/interfaces/VerticalServiceGroup';
import { VerticalServiceProgressModel } from '../../../../services/interfaces/VerticalServiceProgressModel';
import { Colors } from '../../../../theme/v2/variants';
import { Model } from './Model';
import { getPeriodBySelectedTabForPrintedState } from './logic';

export type ConstructionDatesProps = {
  isCustomRange?: boolean;
  beginningDate: Date;
  endDate: Date;
  constructionDays: number;
  constructionWeekDays: number;
  constructionBeginningDate?: Date;
  constructionEndDate?: Date;
};

type Props = {
  constructionDates?: ConstructionDatesProps;
  tableContent: VerticalServiceGroup[];
  selectedTab: string;
  timePeriodListState: any[];
  showItemsSameDataByGroup: boolean;
  progressByConstruction?: VerticalServiceProgressModel;
  dates?: { startDate: Date; endDate: Date };
};

const VerticalServicePageToPdf = ({
  tableContent,
  constructionDates,
  showItemsSameDataByGroup,
  progressByConstruction,
  selectedTab = 'Semanal',
  timePeriodListState = [],
  dates,
}: Props) => {
  const { constructionId, constructions } = useDashboard();
  const constructionService = useMemo(() => new ConstructionService(), []);

  const [generating, setGenerating] = useState(false);
  const pdfRefPageHeader = useRef(null);
  const pdfRefTableHeader = useRef(null);
  const pdfRefTableContent = useRef(null);

  const [selectedConstructionName, setSelectedConstructionName] = useState('');
  const [selectedClientName, setSelectedClientName] = useState('');
  const [constructionImage, setConstructionImage] = useState('');

  const findConstructionImage = async (constructionId: number) => {
    const response = await constructionService.getConstructionImage(constructionId);
    setConstructionImage(response);
    if (response === 'Imagem não encontrada') setConstructionImage('');
  };

  const loadPageData = useCallback(
    async (constructionId: number) => {
      const constructionInfo = await constructionService.findById(constructionId);
      setSelectedClientName(constructionInfo?.clientId?.name);
    },
    [constructionService]
  );

  useEffect(() => {
    if (constructionId) {
      findConstructionImage(constructionId);
      const constructionName = constructions.find(
        (construction) => construction.id === constructionId
      );
      setSelectedConstructionName(constructionName?.name || '');
    }
  }, [constructionId, constructions]);

  useEffect(() => {
    loadPageData(constructionId);
  }, [constructionId, loadPageData]);

  const isConstructionNotSelected = useMemo(
    () => constructionId === null || constructionId === undefined || constructionId === 0,
    [constructionId]
  );

  const reportFileName = useMemo(
    () =>
      `Vertical de Servicos_${selectedConstructionName}_${
        dates
          ? getPeriodBySelectedTabForPrintedState(
              dates.startDate.toISOString(),
              dates.endDate.toISOString(),
              selectedTab
            )
          : ''
      }.pdf`,
    [dates, selectedConstructionName, selectedTab]
  );

  const addPdfRowRecursively = useCallback(
    (doc: any, header: any, rows: any, i = 0) => {
      if (i === 0) {
        doc.html(header, {
          autoPaging: 'text',
          y: 103 + (i + 1) * 22,
          x: 13,
          html2canvas: {
            scale: 0.42,
          },
          callback: function (doc: any) {
            doc.html(rows[i], {
              autoPaging: 'text',
              y: 125 + (i + 1) * 22,
              x: 13,
              html2canvas: {
                scale: 0.42,
              },
              callback: function (doc: any) {
                if (i === rows.length - 1) {
                  doc.save(reportFileName);
                } else {
                  addPdfRowRecursively(doc, header, rows, i + 1);
                }
              },
            });
          },
        });
      } else if (i < 13) {
        doc.html(rows[i], {
          autoPaging: 'text',
          y: 125 + (i + 1) * 22,
          x: 13,
          html2canvas: {
            scale: 0.42,
          },
          callback: function (doc: any) {
            if (i === rows.length - 1) {
              setGenerating(false);
              doc.save(reportFileName);
            } else {
              addPdfRowRecursively(doc, header, rows, i + 1);
            }
          },
        });
      } else {
        const mod = i % 13;

        if (mod === 0) {
          doc.html(header, {
            autoPaging: 'text',
            y: doc.internal.pageSize.height * Math.floor(i / 13) + (mod + 1) * 22,
            x: 13,
            html2canvas: {
              scale: 0.42,
            },
            callback: function (doc: any) {
              doc.html(rows[i], {
                autoPaging: 'text',
                y: doc.internal.pageSize.height * Math.floor(i / 13) + 22 + (mod + 1) * 22,
                x: 13,
                html2canvas: {
                  scale: 0.42,
                },
                callback: function (doc: any) {
                  if (i === rows.length - 1) {
                    setGenerating(false);
                    doc.save(reportFileName);
                  } else {
                    addPdfRowRecursively(doc, header, rows, i + 1);
                  }
                },
              });
            },
          });
        } else {
          doc.html(rows[i], {
            autoPaging: 'text',
            y: doc.internal.pageSize.height * Math.floor(i / 13) + 22 + (mod + 1) * 22,
            x: 13,
            html2canvas: {
              scale: 0.42,
            },
            callback: function (doc: any) {
              if (i === rows.length - 1) {
                setGenerating(false);
                doc.save(reportFileName);
              } else {
                addPdfRowRecursively(doc, header, rows, i + 1);
              }
            },
          });
        }
      }
    },
    [reportFileName]
  );

  const shareOnClick = useCallback(async () => {
    setGenerating(true);

    const inputPagePageHeader = pdfRefPageHeader?.current;
    const inputTableHeader = pdfRefTableHeader?.current;
    const inputTableContent: HTMLElement | null = pdfRefTableContent?.current as HTMLElement | null;

    if (!inputPagePageHeader) return;
    if (!inputTableHeader) return;
    if (!inputTableContent) return;

    const rows = inputTableContent?.children[0]?.children[1].children;
    const rowsHeader = inputTableContent?.children[0]?.children[0];

    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'px',
      format: 'a4',
      putOnlyUsedFonts: true,
    });

    doc.html(inputPagePageHeader, {
      margin: [15, 0, 15, 0],
      autoPaging: 'text',
      html2canvas: {
        scale: 0.42,
      },
      callback: function (doc) {
        doc.html(inputTableHeader, {
          autoPaging: 'text',
          y: 100,
          html2canvas: {
            scale: 0.42,
          },
          callback: function (doc) {
            addPdfRowRecursively(doc, rowsHeader, rows);
          },
        });
      },
    });
  }, [addPdfRowRecursively]);

  return (
    <ComponentWrapper>
      <LoadingButton
        variant='outlined'
        loadingPosition={'start'}
        loading={generating}
        disabled={generating}
        style={{
          color: '#fff',
          backgroundColor: Colors.primaryColor,
          height: '40px',
          borderRadius: '20px',
          width: '160px',
        }}
        startIcon={null}
        onClick={() => shareOnClick()}
      >
        Exportar PDF
      </LoadingButton>
      <Model
        isConstructionNotSelected={isConstructionNotSelected}
        pdfRefPageHeader={pdfRefPageHeader}
        pdfRefTableHeader={pdfRefTableHeader}
        pdfRefTableContent={pdfRefTableContent}
        tableContent={tableContent}
        progressByConstruction={progressByConstruction}
        constructionDates={constructionDates}
        timePeriodListState={timePeriodListState}
        clientName={selectedClientName}
        constructionName={selectedConstructionName}
        selectedTab={selectedTab}
        showItemsSameDataByGroup={showItemsSameDataByGroup}
        dates={dates}
        constructionImage={constructionImage}
      />
    </ComponentWrapper>
  );
};

const ComponentWrapper = styled.div`
  width: 100vw;
`;

export default VerticalServicePageToPdf;
