import styled from 'styled-components';
import { Colors } from '../../../theme/v2/variants';
import { MOBILE_BREAKPOINT } from '../../../utils/mobileDimen';
import Tab from '@mui/material/Tab';

export const FullItemContainer = styled.div<{ columnQuantity: number }>`
  display: grid;
  grid-template-columns: 190px ${(props) => [...Array(props.columnQuantity - 1)].map((_) => ' 1fr')};
  max-width: 100%;
  background: #dddddd;
`;

export const PageContainer = styled.div`
  display: grid;
  width: 1520px;
  height: 100vh;
  padding-top: 20px;
  grid-template-columns: 1fr;
  background-color: ${Colors.neutralColorSurface};
  align-content: start;
`;

export const ContentContainer = styled.div`
  max-width: 100%;
  height: 100%;
`;

export const HeaderContainer = styled.div`
  display: grid;
  width: 1520px;
`;

export const AboutContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: max-content;
  align-content: start;
  overflow: visible;
`;

export const PrintStatePageDescriptionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content max-content;
  align-content: start;
  margin-left: 32px;
  margin-right: 32px;
  row-gap: 12px;
  justify-self: flex-end;
`;

export const PrintStatePageDescriptionText = styled.div`
  font-family: 'Noto Sans', Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${Colors.primaryColor};
`;

export const AdvancementContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0px, 384px);
  column-gap: 80px;
  margin-top: 4px;
  margin-left: 32px;
  margin-right: 32px;
`;

export const TableContainer = styled.div`
  width: 100%;
  height: max-content;
  margin-left: 34px;
  padding-right: 62px;
  overflow-x: scroll;
  overflow-y: hidden;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 24px;
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const VerticalListRoot = styled.div<{ columnQuantity: number }>`
  display: grid;
  grid-template-columns: 190px repeat(${(props) => props.columnQuantity}, 1fr);

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: minmax(0px, 135px) repeat(${(props) => props.columnQuantity}, 1fr);

    overflow-x: auto;
  }
`;

export const HorizontalListPrimaryContainer = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;
  overflow-x: scroll;
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TabContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    width: 799px;
    justify-self: end;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 100%;
    justify-self: end;
  }
`;

export const CustomTab = styled((props) => <Tab {...props} />)(() => ({
  fontFamily:
    'Noto Sans, Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
  fontSize: 16,
  fontStyle: 'normal',
  lineHeight: 'normal',
  fontWeight: 400,
  paddingLeft: 48,
  paddingRight: 48,
  '&.Mui-selected': {
    color: `${Colors.primaryColorDark}`,
    fontWeight: 700,
  },
}));

// eslint-disable-next-line prettier/prettier
export const HorizontalListContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: max-content max-content;
`;

export const EmptyGridItem = styled.div<{ isPrintState: boolean }>`
  ${({ isPrintState }) =>
    !isPrintState &&
    `
    width: 183px;
  `}

  ${({ isPrintState }) =>
    isPrintState &&
    `
    max-width: 183px;
  `}

  height: 48px;
  background: transparent;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 135px;
  }
`;

export const ItemHeaderGridContainerEmpty = styled.div<{
  isPrintState: boolean;
}>`
  ${({ isPrintState }) =>
    !isPrintState &&
    `
    width: 183px;
  `}
  ${({ isPrintState }) =>
    isPrintState &&
    `
    max-width: 183px;
  `}

  height: 52px;
  background: #dddddd;
  border-left: 1px #d4d4d4 solid;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 135px;
    height: 48px;
  }
`;

export const ItemHeadTypeIndicator = styled.div`
  width: 190px;
  height: 52px;
  background: #dddddd;
  display: grid;
  justify-content: left;
  align-content: center;
  font-size: 12px;
  font-family: 'Noto Sans', Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 700;
  word-wrap: break-word;
  padding-left: 16px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 135px;
    height: 48px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
`;

export const ItemHeadServiceIndicator = styled.div`
  width: 190px;
  height: 52px;
  background: #f8f9fc;
  border-bottom: 1px #d4d4d4 solid;
  font-size: 12px;
  font-family: 'Noto Sans', Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 700;
  word-wrap: break-word;
  padding-left: 16px;
  display: grid;
  justify-content: left;
  align-content: center;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 135px;
    height: 48px;
    font-size: 10px;
  }
`;

export const ItemVerticalListHeader = styled.div`
  width: 100%;
  height: 52px;
  font-size: 14px;
  font-family: 'Noto Sans', Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 600;
  word-wrap: break-word;
  border-bottom: 1px #d4d4d4 solid;
  display: grid;
  justify-content: center;
  align-content: center;
`;

export const FullItemEmptyContainer = styled.div``;

// eslint-disable-next-line prettier/prettier
export const FullItemEmptyContainerCustomGrid = styled(FullItemEmptyContainer)<{
  gridTemplate: string;
}>`
  display: grid;
  grid-template-columns: ${(props) => props.gridTemplate};
  background: #f8f9fc;
  max-width: 100%;
  height: 52px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 48px;
  }
`;

export const Title = styled.div`
  max-width: 100%;
  color: ${Colors.primaryColor};
  font-size: 32px;
  font-weight: 700;
  word-wrap: break-word;
  justify-self: center;
`;

export const PeriodBold = styled.span`
  font-size: 14px;
  font-weight: 700;
`;

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const LogoClient = styled.img`
  height: auto;
  width: 150px;
  margin-left: 32px;
`;
