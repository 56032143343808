import styled from "styled-components";
import { Colors } from "../../theme/v2/variants";
import { MOBILE_BREAKPOINT } from "../../utils/mobileDimen";
import typography from "../../theme/v2/typography";
import { Button } from "@mui/material";

export const ImageOptionsContainer = styled.div<{
  top?: string;
  left?: string;
}>`
  width: 261px;
  background-color: ${Colors.neutralColorWhite};
  position: absolute;
  top: ${({ top }) => top || "65px"};
  left: ${({ left }) => left || "15px"};
  border-radius: 16px;
  border: 1px solid rgba(142, 142, 142, 0.25);
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 0px;
  z-index: 9999;
`;

export const InputFile = styled.input`
  padding: 37.5px 25.5px;
  width: 155px;
  height: 141px;
`;

export const HoverButton = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${Colors.primaryColorLight};
  font-size: 14px;
  font-weight: 400;
  padding: 14px 16px;
`;

export const TextButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
`;

export const HeaderContainer = styled.div`
  display: grid;
  max-width: 100%;
  padding: 41px 32px;
  align-items: center;
  background-color: ${Colors.primaryColorLight};

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    /* grid-template-columns: 1fr 1fr; */
    align-content: start;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-rows: max-content max-content;
    padding: 20px;
  }
`;

export const AboutContainer = styled.div`
  display: flex;
  /* overflow: hidden; */
  align-items: center;
  width: 100%;
  position: relative;
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 4px;
  margin-left: 14px;
`;

export const ConstructionImage = styled.img`
  background-color: ${Colors.neutralColorWhite};
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: block;
  object-fit: cover;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 50px;
    height: 50px;
  }
`;

export const ConstructionImagePlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.primaryDarkBlue};
  color: ${Colors.secondaryColorLight};
  min-width: 70px;
  min-height: 70px;
  border-radius: 50%;
  object-fit: cover;
  font-size: ${typography.h1?.fontSize};
  font-weight: ${typography.fontWeightBold};
  text-transform: uppercase;
  user-select: none;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 50px;
    height: 50px;
    font-size: ${typography.h2?.fontSize};
  }
`;

export const PageTitle = styled.div`
  font-size: 16px;
  font-family: "Noto Sans";
  font-weight: 400;
  word-wrap: break-word;
  line-height: 21.79px;
  color: ${Colors.neutralColorWhite};
`;

export const ConstructionNameTitle = styled.div`
  font-size: 32px;
  font-family: "Noto Sans";
  font-weight: 700;
  word-wrap: break-word;
  line-height: 43.58px;
  color: ${Colors.neutralColorWhite};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 80%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 26px;
    width: 70%;
  }

  @media (max-width: 500px) {
    font-size: 22px;
    width: 55%;
  }
`;
